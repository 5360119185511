import React from 'react';
import { Link } from 'react-router-dom';

const NewsItem = ({ date, content }) => (
  <div className="mb-8 border-b border-gray-200 pb-8">
    <h3 className="text-lg font-semibold text-gray-900 mb-2">{date}</h3>
    <div className="text-gray-600 space-y-4" dangerouslySetInnerHTML={{ __html: content }} />
  </div>
);

const News = ({ limit }) => {
  const newsItems = [
    {
      date: "September 2024",
      content: `
        <p>Congratulations to Lupita Garcia and Chris Schmidt on the publication of their new perspective on the Tumor Microenvironment in Therapy Resistance, published in Frontiers in Lab on a Chip Technologies! Article can be accessed <a href="https://www.frontiersin.org/journals/lab-on-a-chip-technologies/articles/10.3389/frlct.2024.1420233/full?utm_source=Email_to_authors_&utm_medium=Email&utm_content=T1_11.5e1_author&utm_campaign=Email_publication&field&journalName=Frontiers_in_Lab_on_a_Chip_Technologies&id=1420233" target="_blank" rel="noopener noreferrer" style="color: blue;">here</a></p>
        <p>Welcome to graduate students Rachael Coates (PhD, BioE) and Yeqing Ni (PhD, MIE) to the lab!</p>
      `,
    },
    {
      date: "June 2024",
      content: `
        <p>Congratulations to Lupita and Maddie for their excellent poster presentation at the BioE Symposium at Northeastern University!</p>
        <p>Congratulations to Christopher for receiving the Grant in Aid of Research Program from the Sigma Xi Scientific Research Honor Society!</p>
      `,
    },
    {
      date: "March 2024",
      content: `
        <p>Congratulations to Maddie for receiving an AJC Merit Research Scholarship from the Undergraduate Research and Fellowships office at Northeastern to do a co-op in the lab in Summer 2 + Fall 2024. We're very excited to have you stay with us for your co-op project!</p>
      `,
    },
    {
      date: "February 2024",
      content: `
        <p>Congratulations to Amina for receiving the PEAK award from the Undergraduate Research and Fellowships office at Northeastern!</p>
        <p>Congratulations to Madeline, Riddhi, Samantha, and Christian, whose abstracts were accepted for presentations at the RISE research showcase at Northeastern University!</p>
      `,
    },
    {
      date: "January 2024",
      content: `
        <p>The TUMOR lab welcomes 7 new members: 2 graduate students, Lupita (PhD, MIE) and Riddhi (MS, BioE) and 5 undergraduates, Madeline (BS, ChemE/Biochem), Chris (BS, BioE), Amina (BS, Biochem), Christian (BS, BioE, UPLIFT 2024), and Sam (BS, BioE, UPLIFT 2024)! We are excited to have you join us!</p>
        <p>Cynthia gave an invited talk at Worcester Polytechnic Institute as part of the Mechanical and Materials Engineering seminar series.</p>
      `,
    },
    {
      date: "December 2023",
      content: `
        <p>Cynthia gave 2 workshops on microfluidics in cancer to high-school students interested in STEM as part of the Building Bridges program of the Center for STEM education at Northeastern!</p>
      `,
    },
    {
      date: "June 2023",
      content: `
        <p>We are hiring! The TUMOR lab is looking for graduate and undergraduate students to join our team and apply their engineering skills to cancer biology. For more information, visit the “Join” page on the lab website.</p>
      `,
    },
  ];

  const displayedNews = limit ? newsItems.slice(0, limit) : newsItems;

  return (
    <div className="mt-8">
      <div className="space-y-8">
        {displayedNews.map((item, index) => (
          <NewsItem key={index} date={item.date} content={item.content} />
        ))}
      </div>
      {limit && newsItems.length > limit && (
        <div className="mt-8 text-center">
          <Link
            to="/news"
            className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-neu-red bg-neu-red-light hover:bg-neu-red-lighter"
          >
            View all news
            <svg className="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
          </Link>
        </div>
      )}
    </div>
  );
};

export default News;

import React from 'react';
import { Link } from 'react-router-dom';

import CynthiaHajal from "../images/cynthia.jpg";
import GuadalupeGarcia from "../images/guadalupe.jpeg";
import MadelineSzoo from "../images/madeline.jpg";
import RiddhiUrsekar from "../images/riddhi.jpeg";
import SamanthaPemberthy from "../images/samantha.png";
import ChristopherSchmidt from "../images/christopher.jfif";
import ChristianAlfieri from "../images/christian.jpg";
import googleScholarPng from "../images/googleScholar.png";
import orcidJpg from "../images/orcid.jpg";
import '../App.css';

const TeamMember = ({ name, image, role, description, links }) => (
  <div className="mb-16 border-b border-gray-200 pb-16 last:border-b-0">
    <div className="flex flex-col md:flex-row">
      <div className="md:w-1/3 mb-6 md:mb-0">
        <img src={image} alt={name} className="w-full h-auto rounded-lg shadow-lg fixed-size" />
        <div className="mt-4 flex justify-center space-x-4">
          {links.map((link, index) => (
            <a
              key={index}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-neu-red hover:text-neu-red-dark transition-colors"
            >
              {link.icon}
            </a>
          ))}
        </div>
      </div>
      <div className="md:w-2/3 md:pl-8">
        <h3 className="text-2xl font-bold text-gray-900 mb-2">{name}</h3>
        <p className="text-lg text-neu-red mb-4">{role}</p>
        <div className="prose prose-lg text-gray-600">
          {description.split('\n').map((paragraph, index) => (
            <p key={index} className="mb-4">{paragraph}</p>
          ))}
        </div>
      </div>
    </div>
  </div>
);

const Team = () => {
  return (
    <div className="bg-gray-50 min-h-screen py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base font-semibold text-neu-red tracking-wide uppercase">Who we are</h2>
          <h1 className="text-6xl font-extrabold text-gray-900 mb-12 text-center">Our Team</h1>
        </div>

        <section className="mb-20">
          <h2 className="text-3xl font-bold text-gray-900 mb-8 border-b border-gray-300 pb-4">Principal Investigator</h2>
          <TeamMember
            name="Cynthia Hajal"
            image={CynthiaHajal}
            role="Assistant Professor"
            description={`Cynthia Hajal, PhD is joining the Department of Mechanical and Industrial Engineering at Northeastern University in August 2023 as an Assistant Professor. Her research interests are at the intersection of engineering and cancer biology, developing tissue engineered models of tumors to study cancer progression and drug delivery.

            She received a BA in Economics and BS in Mechanical Engineering from Columbia University and her SM and PhD in Mechanical Engineering from MIT. As a Ludwig Molecular Oncology Graduate Fellow working with Roger Kamm, PhD, she designed microfluidic models of the vasculature to investigate cancer metastasis and molecular transport between blood and tissues. Dr. Hajal conducted her postdoctoral research with Keith Ligon, MD/PhD at the Dana-Farber Cancer Institute and the Broad Institute of Harvard and MIT. There, she investigated resistance mechanisms to chemotherapies in patient-derived glioma tumor models using functional genomics screens. She was selected as a Rising Star in Mechanical Engineering by MIT (2021).

            Her lab now focuses on the design of engineered tumor models to investigate the role of the components and properties of the microenvironment in cancer progression and treatment outcomes. Outside of the lab, Dr. Hajal loves cooking, long walks, and spending time with family.`}
            links={[
              {
                url: "https://scholar.google.com/citations?user=kIsGWqUAAAAJ&hl=en&oi=ao",
                icon: <img src={googleScholarPng} className="w-6 h-6" alt="Google Scholar"/>
              },
              {
                url: "https://www.linkedin.com/in/cynthia-hajal-7b19b3a5/",
                icon: <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                </svg>
              },
              {
                url: "https://orcid.org/0000-0002-0361-6703",
                icon: <img src={orcidJpg} className="w-6 h-6" alt="ORCID"/>
              }
            ]}
          />
        </section>

        <section className="mb-20">
          <h2 className="text-3xl font-bold text-gray-900 mb-8 border-b border-gray-300 pb-4">Postdoctoral Associates</h2>
          <div className="bg-white shadow-lg rounded-lg p-8">
            <h3 className="text-2xl font-bold mb-4">We're Hiring!</h3>
            <p className="text-lg mb-6">Exciting opportunities await in our lab for postdoctoral researchers. For more details, see <Link to="/join" className="text-neu-red hover:text-neu-red-dark underline">here</Link>.</p>
            <a href="mailto:c.hajal@northeastern.edu" className="inline-block bg-neu-red text-white px-6 py-3 rounded-md hover:bg-neu-red-dark transition-colors duration-300 text-lg font-semibold">Apply Now</a>
          </div>
        </section>

        <section className="mb-20">
          <h2 className="text-3xl font-bold text-gray-900 mb-8 border-b border-gray-300 pb-4">PhD Students</h2>
          <TeamMember
            name="Guadalupe (Lupita) Garcia"
            image={GuadalupeGarcia}
            role="PhD Student"
            description={`Guadalupe (Lupita) Garcia is a Spring 2024 Mechanical Engineering PhD student. She received her Bachelors of Science in Biomedical Engineering from Boston University. She worked as a research technician in the lab of Dr. Keith Ligon, MD/PhD in the department of Pathology at Dana-Farber Cancer Institute. There she conducted both in vitro and in vivo studies to better understand the disease pathology of gliomas through acquired chemotherapy resistance.

            Lupita joined the TUMOR lab in Spring 2024 and looks forward to continuing cancer research and studying chemotherapy transport and resistance.`}
            links={[
              {
                url: "https://www.linkedin.com/in/guadalupe-g/",
                icon: <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                </svg>
              },
              {
                url: "https://orcid.org/0000-0002-8673-2303",
                icon: <img src={orcidJpg} className="w-6 h-6" alt="ORCID"/>
              }
            ]}
          />
        </section>

        <section className="mb-20">
          <h2 className="text-3xl font-bold text-gray-900 mb-8 border-b border-gray-300 pb-4">Masters Students</h2>
          <TeamMember
            name="Riddhi Ursekar"
            image={RiddhiUrsekar}
            role="Masters Student"
            description={`Riddhi Ursekar is a Fall 2023 Bioengineering master's student with a concentration in cell and tissue engineering. She has received a M.Tech Integrated degree in Biotechnology from D Y Patil University, Mumbai, India. She worked as a Junior Research Fellow at Tata memorial Centre advanced centre for treatment research and education in cancer (ACTREC) where her work was focused on utilizing circulating tumour DNA for monitoring patient relapse following endocrine hormone therapy. Riddhi joined the TUMOR lab in Fall 2023 and is studying the transport of biological solutes and therapeutics in the microenvironment of tumors.`}
            links={[
              {
                url: "https://www.linkedin.com/in/riddhi-ursekar/",
                icon: <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                </svg>
              }
            ]}
          />
          <TeamMember
            name="Christopher Schmidt"
            image={ChristopherSchmidt}
            role="Masters Student"
            description={`Christopher Schmidt is a Fall 2024 Bioengineering Master's student with a concentration in Cell and Tissue Engineering. He previously received his Bachelor of Science in Bioengineering here at Northeastern in May 2024. He has done prior co-ops at Cerevel in Formulation Development and at Ensoma in Downstream Process Development.

            He joined the lab in January 2024 and is excited to conduct research into the role of the tumor microenvironment in treatment resistant tumors.`}
            links={[
              {
                url: "https://www.linkedin.com/in/christopher-schmidt-824b3414a/",
                icon: <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                </svg>
              }
            ]}
          />
        </section>

        <section className="mb-20">
          <h2 className="text-3xl font-bold text-gray-900 mb-8 border-b border-gray-300 pb-4">Undergraduate Students</h2>
          <TeamMember
            name="Madeline Szoo"
            image={MadelineSzoo}
            role="Undergraduate Student"
            description={`Madeline Szoo (she/her) is a fourth-year honors Chemical Engineering and Biochemistry student with a minor in Women's, Gender, and Sexuality Studies as well. Her research interests include biomaterials, disease modeling, and drug delivery, with a focus on cancer and the tumor microenvironment.

            She has previously conducted research at the Hasan Lab at the Wellman Center for Photomedicine at Massachusetts General Hospital and Harvard Medical School through the CaNCURE program, at Beam Therapeutics as a Hematology Early Discovery co-op, and in the labs of Prof. Debra Auguste and Prof. Ryan Koppes in the Chemical Engineering Department at Northeastern University.`}
            links={[
              {
                url: "https://scholar.google.com/citations?user=p_LgkdkAAAAJ&hl=en",
                icon: <img src={googleScholarPng} className="w-6 h-6" alt="Google Scholar"/>
              },
              {
                url: "https://www.linkedin.com/in/madeline-szoo",
                icon: <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                </svg>
              },
              {
                url: "https://orcid.org/0009-0003-8562-1702",
                icon: <img src={orcidJpg} className="w-6 h-6" alt="ORCID"/>
              }
            ]}
          />
          <TeamMember
            name="Samantha Serna Pemberthy"
            image={SamanthaPemberthy}
            role="Undergraduate Student"
            description={`Samantha Serna Pemberthy (she/her) is a first-year Bioengineering student with a minor in Cell and Molecular Biology. She joined the TUMOR Lab in the Spring of 2024, and her interests are artificial organs or alternative organ research, neurology, and engineering cancer research.

            She worked previously as an intern at Brigham and Women's Hospital at the Lemere Lab, a Neurology Lab. She worked alongside Dr. Cynthia A. Lemere, trained with technicians and postdocs in the lab, learned to perform mouse brain sectioning and immunohistochemistry, and quantified the staining using image analysis.`}
            links={[
              {
                url: "https://www.linkedin.com/in/samantha-serna-pemberthy-6711a628b/",
                icon: <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                </svg>
              }
            ]}
          />
          <TeamMember
            name="Christian Alfieri"
            image={ChristianAlfieri}
            role="Undergraduate Student"
            description="Christian Alfieri (he/him) is a second-year Chemical Engineering and Biochemistry student in the College of Engineering. He joined the lab in December, 2024 through the UPLIFT Research program. His interests lie in Chemotherapeutic resistance and Molecular Cancer biology."
            links={[
              {
                url: "https://www.linkedin.com/in/christian-alfieri-98a581299",
                icon: <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                </svg>
              }
            ]}
          />
        </section>

        <section className="mb-20">
          <h2 className="text-3xl font-bold text-gray-900 mb-8 border-b border-gray-300 pb-4">Alumni</h2>
          <TeamMember
            name="Amina Abdirahman"
            image="https://eu.ui-avatars.com/api/?name=Amina+Abdirahman&size=250"
            role="Former Undergraduate Student"
            description=""
            links={[]}
          />
        </section>
      </div>
    </div>
  );
};

export default Team;